import { render, staticRenderFns } from "./initialimportmessage.vue?vue&type=template&id=f16435c4&scoped=true&"
import script from "./initialimportmessage.vue?vue&type=script&lang=js&"
export * from "./initialimportmessage.vue?vue&type=script&lang=js&"
import style0 from "./initialimportmessage.vue?vue&type=style&index=0&id=f16435c4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f16435c4",
  null
  
)

export default component.exports